.mbsc-ios.mbsc-textfield-wrapper {
  border-radius: 4px;
  height: 56px;
  margin: 0 !important;
}
.mbsc-ios.mbsc-textfield-wrapper:not(.mbsc-error) {
  border-radius: 4px;
  border: 1px solid var(--neutrals-black);
}
.mbsc-ios.mbsc-textfield-wrapper > span {
  height: 100%;
}
.mbsc-ios.mbsc-textfield-wrapper > span > input {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  line-height: 24px;
}
.mbsc-ios.mbsc-textfield-wrapper > span > input::placeholder {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  line-height: 24px;
  opacity: 1;
}
.mbsc-ios.mbsc-textfield-wrapper > span > input.mbsc-error {
  border-radius: 4px;
  border-color: var(--error-default);
}
.mbsc-ios.mbsc-textfield-wrapper > span > input::placeholder:not(.mbsc-error) {
  color: var(--neutrals-black);
}
.mbsc-ios.mbsc-textfield-wrapper > span > input.mbsc-error::placeholder {
  color: var(--error-default);
}
.mbsc-ios.mbsc-textfield-wrapper > span > .mbsc-ios.mbsc-error-message {
  color: var(--error-default);
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
  padding-left: 16px;
}
.mbsc-calendar-button.mbsc-button.mbsc-reset {
  color: #37383e !important;
  position: absolute;
  stroke: #63656e !important;
  top: -5px;
}

.mbsc-calendar-button-next > span > svg,
.mbsc-calendar-button-prev > span > svg {
  fill: #63656e !important;
}

.mbsc-calendar-button-next {
  right: 0;
}

.mbsc-popup-content {
  display: flex;
  height: 393px;
  padding: 24px;
}

.mbsc-calendar-button.mbsc-button.mbsc-reset > span {
  font-family: 'Inter', sans-serif;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.mbsc-calendar-header {
  margin-bottom: 12px;
}

.mbsc-calendar-body {
  color: #37383e !important;
  font-family: 'Inter', sans-serif;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}
.mbsc-ios.mbsc-calendar-week-day {
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 40px !important;
  line-height: 21px !important;
  width: 40px !important;
}

.mbsc-calendar-day-inner {
  font-size: 13px !important;
  height: 40px !important;
  width: 40px !important;
}

.mbsc-calendar-cell-text {
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}

.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
  background-color: #476ff2 !important;
}

.mbsc-ios.mbsc-hover .mbsc-calendar-cell-text {
  background-color: #d6e2ff !important;
  border-color: #d6e2ff !important;
  color: var(--primary-default) !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  height: 35px !important;
  width: 35px !important;
}

.mbsc-ios.mbsc-calendar-day-text {
  height: 35px !important;
  margin: 0 !important;
  padding: 0.5px !important;
  width: 35px !important;
}

.mbsc-calendar-cell.mbsc-calendar-month.mbsc-ios.mbsc-ltr,
.mbsc-calendar-cell.mbsc-calendar-year.mbsc-ios.mbsc-ltr {
  height: 74px !important;
}
