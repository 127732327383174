.new-insight {
  background: var(--neutrals-white);
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100% - 100px);
  padding: 24px;
  padding-bottom: 0;
  padding-right: 0;
  position: fixed;
  right: -415px;
  top: 100;
  transition: all 0.6s ease;
  width: 100%;
  z-index: 100;
  max-width: 412px;
  box-shadow: -4px -2px 4px 0px #00000005;
}

.open {
  right: 0;
}

.content-wrapper {
  overflow: scroll;
  height: 100%;
  padding-bottom: 24px;
  padding-right: 20px;
  margin-right: 4px;
}

.content-btn-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.insights-form > span {
  color: var(--neutrals-black);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.new-insight-header {
  display: flex;
  height: 64px;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 24px;
}

.new-insight-header > img {
  cursor: pointer;
}

.new-insight-header > span {
  color: var(--primary-default);
  font-size: 24px;
  font-weight: 700;
  line-height: 64px;
  min-height: 64px;
}

.new-insight-autoPopulate {
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.new-insight-autoPopulate > div {
  display: flex;
  gap: 4px;
  align-items: baseline;
  justify-content: flex-start;
}

.new-insight-autoPopulate > div > span {
  color: var(--neutrals-black);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 20px;
  text-align: center;
}

.new-insight-autoPopulate > div > .info-icon {
  cursor: pointer;
}

.insights-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.general-wrapper,
.general-wrapper-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.insights-form > button {
  align-self: center;
  border-radius: 4px;
  height: 36px;
  max-width: 131px;
  padding: 0px 16px;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 700;
}

.field > .error-message,
.insights-form > .error-message {
  color: var(--error-default);
  font-size: 12px;
  font-weight: 400;
  padding-left: 16px;
}

.error-request {
  align-items: center;
  background-color: #b3261e1f;
  border-radius: 8px;
  color: #b3261e;
  display: flex;
  padding: 6px;
}

.error-request > span {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  width: 100%;
}

.loader {
  margin-top: 8px;
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.insights-form > .create-digest-button,
.insights-form > .preview-button,
.external-url-button {
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 700;
  height: 36px;
  letter-spacing: 0.1px;
  line-height: 20px;
  max-width: 100%;
  padding: 8px 16px;
  text-align: center;
  width: fit-content;
}

.insights-form > .preview-button,
.external-url-button {
  background: none;
  border-radius: 4px;
  color: var(--primary-default);
}

.preview-button:hover,
.external-url-button:hover,
.preview-button:hover > svg > path {
  background: var(--primary-light);
  border-radius: 4px;
  color: var(--primary-default);
  stroke: var(--primary-default);
}

.preview-button > svg > path,
.external-url-button > svg > path {
  stroke-width: 0.5px;
  stroke: var(--primary-default);
}

.preview-button:disabled {
  color: var(--neutrals-black);
  opacity: 0.38;
  cursor: not-allowed;
}

.preview-button:disabled > svg > path {
  stroke: var(--neutrals-black);
}

.create-digest-button {
  min-width: 138px;
}
.create-digest-button > .loader {
  margin: 0;
}

.create-digest-button:disabled {
  background: #f4f4f5;
  color: #00000038;
  cursor: not-allowed;
}

.external-url-button {
  display: flex;
  gap: 8px;
  align-items: center;
}

.content-section-wrapper {
  align-items: flex-start;
  background-color: var(--neutrals-200);
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  width: 100%;
  gap: 4px;
}

.content {
  border: 1px solid #0021471a;
  border-left: 0px;
  border-right: 0px;
  color: #002147;
  display: flex;
  list-style: none;
  min-height: 86px;
  padding: 12px 8px;
  width: 100%;
}

.content.dragging :where(.content-data-list) {
  opacity: 0;
}

.content-data-list {
  align-items: center;
  display: flex;
  gap: 8px;
  height: inherit;
  width: 100%;
}

.content-data-list > svg > path {
  stroke: var(--primary-medium);
}

.content-data-list > *:nth-child(3) {
  cursor: pointer;
}

.content-data-list > *:nth-child(1) {
  cursor: move;
}

.enableShare > *:nth-child(1) {
  align-self: flex-start;
}

.content-data-list > *:nth-child(3) > svg > path {
  stroke-width: 2px;
  stroke: var(--primary-medium);
}

.content-name {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.content:hover {
  background: #b1d5ff26;
}

.content:active {
  background: #b1d5ff80;
}

.checkbox-wrapper {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkbox-wrapper > label {
  color: var(--neutrals-400);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.checkbox-wrapper > input[type='checkbox'],
.recipients-checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  position: relative;
  min-height: 18px;
  width: 18px;
  height: 18px;
  border: 2px solid #49454f;
  overflow: hidden;
  border-radius: 2px;
  cursor: pointer;
}

.checkbox-wrapper > input[type='checkbox']::before,
.recipients-checked::before {
  content: '';
  color: #fff;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: var(--primary-medium);
  border: 2px solid var(--primary-medium);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transform: scale(0);
  transform: scale(0);
  width: 100%;
  height: 100%;
  background-image: url('data:image/svg+xml,<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z" fill="white"/></svg>');
}

.checkbox-wrapper > input[type='checkbox']:checked::before,
.recipients-checked:checked::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.checkbox-wrapper > input[type='checkbox']:checked,
.recipients-checked:checked {
  border: 2px solid var(--primary-medium);
}

.upload-container {
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23B4B4B4FF' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='24' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 16px;
  position: relative;
  width: 100%;
}

.upload-container > p {
  color: #43454c;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.drag-indicator {
  width: 100%;
  height: 2px;
  background: var(--primary-default);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
