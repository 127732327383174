.recipients-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.recipients-wrapper > div > * {
  width: 100%;
}

.recipients-wrapper > div > span,
.general-list-header > span,
.general-list-header > div > label,
.list-item {
  color: var(--neutrals-400);
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.general-list-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.general-list-header > span {
  align-items: center;
  display: flex;
}

.general-list-header > div > label {
  font-size: 14px;
  font-weight: 400;
  height: fit-content;
  line-height: 20px;
}
.general-list-header > div {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding-right: 24px;
  width: 50%;
}

.list-item {
  align-items: center;
  border-bottom: 1px solid #00214733;
  display: flex;
  font-weight: 400;
  gap: 16px;
  height: 56px;
  justify-content: space-between;
  list-style-type: none;
  padding: 16px;
  padding-right: 24px;
}

.recipients-checked {
  border-radius: 2px;
  max-height: 18px;
  max-width: 18px;
}

.recipients-checked:checked {
  accent-color: var(--primary-medium);
}

.diffusion-count-wrapper {
  align-items: center;
  display: flex;
  gap: 4px;
  height: 100%;
  justify-content: flex-end;
  width: 25%;
}

.diffusion-count-wrapper > svg {
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
}

.diffusion-count-wrapper > .recipients-checked {
  margin-left: 12px;
}

.general-list-wrapper > .list-item > div:nth-child(2) {
  flex: 1;
  text-align: left;
}

.recipient-avatar {
  align-items: center;
  background: var(--primary-light);
  border-radius: 50%;
  color: var(--primary-default);
  display: flex;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.15;
  width: 40px;
}

.diffusion-button {
  align-items: center;
  align-self: center;
  background: none;
  border-radius: 4px;
  color: var(--primary-default);
  display: flex;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 700;
  gap: 8px;
  height: 36px;
  letter-spacing: 0.1px;
  line-height: 20px;
  max-width: 100%;
  padding: 8px 16px;
  text-align: center;
  transition: 0.6s ease;
  width: fit-content;
}

.diffusion-button:hover {
  background: var(--primary-light);
  border-radius: 4px;
  color: var(--primary-default);
  stroke: var(--primary-default);
}

.diffusion-button > svg > path {
  stroke-width: 0.5px;
  stroke: var(--primary-default);
}

.diffusion-button:disabled {
  background-color: transparent;
  color: var(--neutrals-black);
  cursor: not-allowed;
  opacity: 0.38;
}
.diffusion-button:disabled > svg > path {
  stroke: var(--neutrals-black);
}

.diffusion-list-wrapper > .diffusion-list-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.diffusion-list-wrapper > .diffusion-list-header > span {
  margin-bottom: 2px;
  font-weight: 400;
}
