.custom-select {
  max-width: 100%;
  position: relative;
  width: 400px;
}

.custom-select > * {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.select-button {
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #9ea0a9;
  color: var(--neutrals-black);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  max-height: 56px;
  padding: 16px;
  text-transform: none;
}

.selectError {
  border: 1px solid var(--error-default);
}

.select-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.select-wrapper > .error-message {
  color: var(--error-default);
  font-size: 12px;
  font-weight: 400;
}

.select-button:hover {
  border-color: var(--primary-default);
  color: var(--neutrals-black);
}

.selected-value {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 70%;
}

.select-dropdown {
  background-color: #fff;
  border-radius: 4px;
  border: 1.8px solid #22232680;
  list-style: none;
  max-height: 288px;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px 0;
  position: absolute;
  top: 52px;
  transform: scaleY(0);
  visibility: hidden;
  width: 100%;
}

.select-dropdown li {
  align-items: center;
  color: var(--neutrals-black);
  cursor: pointer;
  display: flex;
  position: relative;
  height: 56px;
}

.select-dropdown li label {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  width: 100%;
}

.select-dropdown > .multiselect > label {
  padding: 8px;
}

.select-dropdown::-webkit-scrollbar {
  width: 7px;
}
.select-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

.select-dropdown::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 25px;
}

.select-dropdown li:hover,
.select-dropdown input:checked :where(:not(.multiselect)) ~ label {
  background-color: var(--primary-light);
}

.select-dropdown .search-li:hover,
.select-dropdown .create-li:hover {
  background-color: transparent;
}

.select-dropdown input:focus :where(:not(.multiselect)) ~ label {
  background-color: #f4f6f7;
}

.select-dropdown input[type='radio'] {
  left: 0;
  opacity: 0;
  position: absolute;
}

/* interactivity */

.custom-select.active .arrow {
  transform: rotate(180deg);
}

.custom-select.active .select-dropdown {
  opacity: 1;
  transform: scaleY(1);
  visibility: visible;
  z-index: 1000;
  box-shadow: 0px 5px 5px 0px #00000012;
}

.search-input {
  background: url('../../../assets/img/search.svg') no-repeat left #fff;
  background-position: 15px;
  border-radius: 4px;
  border: 1px solid #b9bbc1;
  margin: 0 12px 8px 12px;
  max-height: 48px;
  padding: 16px;
  padding-left: 44px;
  font-size: 16px;
  font-weight: 400;
}

.search-input::placeholder {
  color: var(--neutrals-400);
  font-family: 'Inter', sans-serif;
}

.select-creation {
  height: 24px;
  background-color: transparent;
  color: var(--primary-medium);
  background: url('../../../assets/img/add.svg') no-repeat left #fff;
  background-position: 15px;
  text-transform: none;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 44px;
}

.select-creation:hover {
  color: var(--primary-medium);
}

.select-option > input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--neutrals-white);
  border-radius: 4px;
  border: 1px solid #b9bbc1;
  cursor: pointer;
  height: 20px;
  margin-left: 16px;
  min-height: 20px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 20px;
}

.select-option > input[type='checkbox']::before {
  -webkit-transform: scale(0);
  background-color: var(--primary-light);
  background-image: url('data:image/svg+xml,<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10.6666 1.5L4.24992 7.91667L1.33325 5" stroke="RoyalBlue" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 70%;
  border-radius: 4px;
  border: 1px solid var(--primary-labels);
  color: #fff;
  content: '';
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: scale(0);
  width: 100%;
}

.select-option > input[type='checkbox']:checked::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.select-option > input[type='checkbox']:checked {
  border: 1px solid var(--primary-labels);
}
