.default-textarea {
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  line-height: 24px;
  max-height: 56px;
  max-height: fit-content;
  padding: 16px;
  resize: none;
  width: 100%;
}

.default-textarea::placeholder {
  color: var(--neutrals-400);
  font-family: 'Inter', sans-serif;
}

.field {
  color: var(--neutrals-400);
  position: relative;
}

.field > label {
  color: var(--neutrals-400);
  font-size: 16px;
  font-weight: 400;
  left: 16px;
  line-height: 24px;
  position: absolute;
  top: 14px;
  transition: top 0.3s ease;
}

.field:focus-within > label {
  background-color: var(--neutrals-white);
  color: var(--primary-default);
  font-size: 12px;
  line-height: 16px;
  padding: 0 4px;
  position: absolute;
  top: -8px;
}

.field > .label-error,
.error-message {
  color: var(--error-default);
}

.error-message {
  font-size: 12px;
  font-weight: 400;
  padding-left: 12px;
}

.textarea-error {
  border: 1px solid var(--error-default);
}
