@import './assets/css/global.css';
@import './assets/css/page.css';
@import './assets/css/button-bar.css';

:root {
  /*Primary*/
  --primary-default: #003470;
  --primary-medium: #115099;
  --primary-labels: #2b71c2;
  --primary-light: #eef6ff80;

  /*Secondary*/
  --feedback-warning-100: #ffdcad;
  --secondary-default: #ef7e15;
  --feedback-warning-900: #764300;

  /*Neutrals*/
  --neutrals-white: #ffffff;
  --neutrals-200: #ebebeb;
  --neutrals-300: #dcdce0;
  --neutrals-400: #43454c;
  --neutrals-black: #262626;

  --error-default: #8f0000;
  --error-100: #ffd6d6;
}

body {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
