.tab-container {
  border-radius: 4px;
  border: 1px solid var(--primary-default);
  display: flex;
  margin: 0 4px;
  max-width: 215px;
  overflow: hidden;
}

.custom-tab {
  background: var(--neutrals-white);
  border-radius: 0;
  color: var(--primary-default);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
  padding: 10px 33px;
  text-align: center;
  text-transform: initial;
}
.custom-tab:hover {
  background: var(--primary-light);
  color: var(--primary-default);
}

.custom-tab.active {
  background: var(--primary-default);
  color: var(--neutrals-white);
}
