.card-options {
  cursor: pointer;
}

.options-container {
  background: var(--neutrals-white);
  border-radius: 8px;
  border: 1px solid var(--neutrals-300);
  box-shadow: 0px 1px 2px 0px #00000012;
  min-width: 130px;
  display: none;
  position: relative;
}

.card-options-open {
  display: block;
  right: 0;
  top: 50px;
}

.option-list {
  margin: 0;
  padding: 8px 0;
}

.option-li,
.content-tags {
  align-items: center;
  color: var(--neutrals-400);
  cursor: pointer;
  display: flex;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  gap: 8px;
  height: 48px;
  line-height: 16.94px;
  list-style: none;
  padding: 12px;
  text-align: left;
}

.option-li > svg {
  max-height: 20px;
  max-width: 20px;
  stroke-width: 2px;
}

.option-li:nth-child(2) > svg > path {
  fill: #63656e;
}

.option-li:hover {
  background: var(--primary-light);
}

.dragged {
  opacity: 0.2;
  transform: translate3d(0, 0, 0);
  border: 1px solid var(--neutrals-400);
  background-color: #f3f3f3;
}

.content-tags {
  background: var(--primary-light);
  border-radius: 22px;
  color: var(--primary-labels);
  display: block;
  height: 24px;
  padding: 4px 8px;
  width: fit-content;
  cursor: default;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

[tooltip] {
  position: relative;
  display: inline-block;
}
[tooltip]::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  z-index: 99;
  opacity: 0;
}

[tooltip-position='top']::before {
  left: 50%;
}

[tooltip]::after {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 2px;
  color: var(--neutrals-white);
  content: attr(tooltip);
  font-size: 12px;
  left: 50%;
  min-width: 80px;
  max-width: 250px;
  opacity: 0;
  padding: 8px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  width: max-content;
  z-index: 99;
}

[tooltip-position='top']::after {
  left: 50%;
}

[tooltip]:hover::after,
[tooltip]:hover::before {
  opacity: 1;
}

@media (max-width: 768px) {
  .option-li:nth-child(1) > svg > path {
    fill: #63656e;
  }
}
