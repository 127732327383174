/***********************************
 ******** the main nav bar *********
 ***********************************/

#nav-main {
  display: flex;
  pointer-events: all;
  /* always on */
  position: fixed;
  z-index: 10;
  border-top: 1px #d9d8d9 solid;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  top: auto;
  bottom: 0;
  /* small screens first */
  height: calc(49px + env(safe-area-inset-bottom));
  left: 0;
  right: 0;
  text-align: center;
  /*background: #f2f2f2;*/
  background: white;
  color: #919191;
  fill: #919191;
  font-family: Arial, sans-serif;

  /* do not allow drag-select text */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

#nav-main.show {
  bottom: 0;
}

#nav-main ul {
  width: 100%;
  max-width: 450px;
  display: flex;
  box-align: stretch;
  align-items: stretch;
  align-content: stretch;
  justify-content: center;
  flex-wrap: nowrap;

  margin: 0 auto;
  /* center it */
  padding: 0;
  list-style: none;
}

#nav-main li {
  text-align: center;
  vertical-align: top;
  width: 15%;
  /* a little less than 100/6 so we have some space left and right */
  max-width: 80px;
  /* so we can use the main nav with only 3 buttons and it'll still look decent */
  position: relative;
  left: auto;
}

#nav-main li:active {
  background: #e3f4fc;
}

#nav-main svg {
  height: 35px;
  width: 40px;
  padding: 3px 7px;
  fill: #919191;
  margin-right: 0;
  top: auto;
  vertical-align: inherit;
}

#nav-main li span {
  display: block;
  color: #919191;
  top: -7px;
  font-family: Arial, sans-serif;
  font-size: 10px;
  position: relative;
}

#nav-main li a {
  padding: 0;
  text-align: center;
  display: block;
  color: #2d498d;
  background: transparent;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}

#nav-main li:hover a,
#nav-main li:active a {
  color: #2d498d;
  fill: #2d498d;
}

#nav-main > ul > li#menu-more {
  position: relative;
  right: unset;
}

/* handle off and on states of the svg icons */
#nav-main svg.on {
  display: none;
}

#nav-main li.active svg {
  display: none;
}

#nav-main li.active svg.on {
  display: inline-flex;
}

/* hide the brand for mobile */
#nav-main > ul > li#branding {
  display: none;
  position: absolute;
  left: 0;
}

/* handle switched look and feel */
#nav-main.switched {
  background: green;
}
#nav-main.switched #branding {
  display: none;
}

#nav-main #switch-warning {
  font-size: 11px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
  background: green;
  color: white;
  white-space: normal;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
#nav-main #switch-warning a {
  color: white;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}
#nav-main #switch-warning svg {
  fill: white;
  width: 20px;
  height: 20px;
  padding: 0;
}

/* Main Nav - menu icon colors */

/* default */
#nav-main > ul > .active .label {
  color: #003470;
}

#nav-main > ul > .insights-tab > a {
  padding-left: 2px;
}

#nav-main > ul > .active svg {
  fill: #003470;
}

/* #nav-main>ul>.active:hover {
  color: #003470;
  fill: #003470;
}
#nav-main>ul:hover {
  color: white;
  fill: white
} */

/* #nav-main>ul .label:hover, #nav-main>ul svg:hover {
  color: white;
  fill: white;
}
#nav-main>ul>.active .label:hover, #nav-main>ul>.active svg:hover{
  color: #003470;
  fill: #003470;
} */

/* notifications badge */

#nav-main label.badge {
  top: -6px;
  right: -8px;
  z-index: 10;
}

/***********************************
 ************ responsive ***********
 ***********************************/

/* iphone 6+ is 414px */
@media (min-width: 450px) {
  #nav-main > ul {
    width: auto;
  }

  #nav-main > ul > li {
    width: auto;
  }

  #nav-main > ul > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 670px) {
  #nav-main > ul > li#branding {
    display: block;
  }
  #nav-main > ul > li#branding img {
    height: 40px;
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
  }
}

/* this is the width we can start showing labels */
@media (min-width: 600px) {
  #nav-main li:not(.active):hover {
    background: #01112f;
  }

  /* DESKTOP layout */

  /* Main Nav */
  #nav-main {
    top: 0;
    border-top: 0;
    bottom: auto;
    /*background: #777;*/
    background: #0a0d28;
    height: 49px;
    z-index: 11;
    box-shadow: none;
  }

  #nav-main > ul {
    max-width: none;
    height: 100%;
  }

  #nav-main > ul > li {
    max-width: 130px;
    height: 100%;
    white-space: nowrap;
  }

  #nav-main > ul > li:first-child {
    margin-left: 0;
  }

  #nav-main > ul > li > a {
    padding: 4px 10px;
    height: 100%;
  }

  #nav-main > ul svg {
    top: 0.125em;
    fill: #bbb;
  }

  #nav-main > ul .label {
    color: #bbb;
  }

  #nav-main > ul > li .label {
    display: inline-block;
    vertical-align: 40%;
    font-size: 0.9em;
    top: -4px;
  }

  #nav-main > ul > li.active {
    background: white;
  }

  /* notifications badge */
  #nav-main label.badge {
    top: 3px;
    /*right: -6px;*/
  }

  #nav-main > ul > li#menu-more {
    position: absolute;
    right: 0;
  }

  #nav-main #menu-more .label {
    display: none;
  }
}
