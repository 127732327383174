/***********************************
 ********* navigation bars *********
 ***********************************/

#secondary-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: calc(49px + env(safe-area-inset-top));

  /* do not allow drag-select text */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

#secondary-container > * {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: calc(49px + env(safe-area-inset-top));
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  transition:
    top 0.3s cubic-bezier(0, 0.75, 0, 1),
    bottom 0.3s cubic-bezier(0, 0.75, 0, 1);
}

/******* Secondary Nav Bars *******/
/* body.page-tiles #secondary-container.nav-tiles #nav-tiles,
body.page-tiles #secondary-container.nav-tiles #tile-create-button,
body.page-tiles #secondary-container.nav-tiles-swipe #nav-tiles-swipe,

body.page-lessons #secondary-container.nav-lessons #nav-lessons,
body.page-lessons #secondary-container.nav-lessons-swipe #nav-lessons-swipe,

body.page-insights #secondary-container.nav-insights #nav-insights,
body.page-insights #secondary-container.nav-insights-swipe #nav-insights-swipe,

body.page-viewer #secondary-container.nav-viewer #nav-viewer,

body.page-driver #secondary-container.nav-driver #nav-driver,
body.page-driver #secondary-container.nav-driver-swipe #nav-driver-swipe,

body.page-account #secondary-container.nav-account #nav-account, */

/* body.page-more #secondary-container.nav-more #nav-more
{
  pointer-events: all;
  top: 0;
  opacity: 1;
} */

/* hide secondary nav when swiper is active */
/* body.page-tiles.swiper #secondary-container #nav-tiles {
  top: -49px;
  opacity: 0;
  pointer-events: none;
} */
/* #secondary-container #nav-tiles-swipe {
  top: 0;
  opacity: 1;
  pointer-events: all;
} */

/***********************************
 ***** secondary nav bars (top) ****
 ***********************************/

/* by default, secondary nav is hidden */
nav.nav-secondary {
  border-bottom: 1px #d9d8d9 solid;
  height: 100%;
  position: relative;
  top: 0;
  /* start with none so we can turn on when we need to */
  display: block;
  text-align: center;
  /*background: #f2f2f2;*/
  background: white;
  color: #919191;
  fill: #919191;
  font-family: Arial, sans-serif;
}

nav.nav-secondary.insights {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  align-items: center;
}

nav.nav-secondary.insights > ul {
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  transform: translateX(0) translateY(0);
}

nav.nav-secondary > ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

nav.nav-secondary > ul > li {
  flex: 1 0 0;
}

nav.nav-secondary > ul > li.btn {
  flex: none;
}

nav.nav-secondary.insights a {
  height: 40px;
}

nav.nav-secondary a {
  padding: 0 10px;
  height: 30px;
  /*line-height: 28px;*/
  min-width: 30px;
  text-align: center;
  color: #003470;
  font-size: 12px;
  font-family: Arial, sans-serif;
  background: white;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 4px;
  border: 1px #003470 solid;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

nav.nav-secondary a > * {
  /* flex: 1 0 0;*/
  align-self: center;
  padding: 0;
}

nav.nav-secondary a:hover {
  background: #cfe5ff;
}

nav.nav-secondary .label {
  display: none;
  padding-left: 5px;
}

nav.nav-secondary .btn:hover > a,
nav.nav-secondary .active a {
  border-color: #003470;
}

nav.nav-secondary .btn:hover > a *,
nav.nav-secondary .btn.active > a * {
  color: #003470;
  fill: #003470;
}

nav.nav-secondary .new-search-btn {
  background: transparent;
  border-radius: 4px;
  border: 1px solid var(--primary-default);
  color: var(--primary-default);
  display: flex;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 700;
  gap: 8px;
  letter-spacing: 0.1px;
  line-height: 20px;
  padding: 10px 16px;
  text-align: center;
  text-transform: uppercase;
  width: fit-content;
}

nav.nav-secondary .new-search-btn:hover {
  box-shadow: 0px 1px 3px 1px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
}

nav.nav-secondary .new-search-btn > svg > path {
  stroke: var(--primary-default);
  stroke-width: 0.8px;
}

nav.nav-secondary .sub-tabs {
  padding-left: 10px;
  padding-right: 10px;
}

nav.nav-secondary svg.icon {
  top: auto;
  width: 18px;
  height: 18px;
  fill: #003470;
  margin: 0;
  padding: 0;
}

.buttons-wrapper {
  display: flex;
  gap: 16px;
  width: calc(50% - 50px);
  justify-content: center;
}

.add-content,
.add-digest {
  align-items: center;
  background: none;
  border-radius: 4px;
  border: 1px solid var(--primary-medium);
  color: var(--primary-default);
  display: flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  max-width: 146px;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
}

.add-digest {
  background: var(--secondary-default);
  border: none;
  color: var(--neutrals-white);
  max-width: 165px;
}

.add-content:hover {
  border: 1px solid var(--primary-default);
  color: var(--primary-default);
  box-shadow: 0px 1px 3px 1px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
}

.add-digest:hover {
  border: 1px solid var(--secondary-default);
  color: var(--neutrals-white);
  box-shadow: 0px 1px 3px 1px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #nav-tiles > ul {
    width: 100%;
  }

  nav.nav-secondary > ul > li:first-child,
  nav.nav-secondary > ul > li:last-child {
    max-width: 80px;
    flex: 1;
  }

  nav.nav-secondary > ul > li.sub-tabs {
    min-width: 250px;
    text-align: center;
  }

  nav.nav-secondary > ul > li.status.sub-tabs {
    min-width: 138px;
  }

  nav.nav-secondary > ul > li.back-to-dashboard {
    min-width: 125px;
  }

  nav.nav-secondary > ul > li.back-to-dashboard a svg {
    max-width: 15px;
  }

  @media (min-width: 321px) {
    #nav-tiles > ul {
      width: 100%;
    }
  }
}

/***********************************
 *** secondary nav bars (inline) ***
 ***********************************/

/* by default, secondary nav is hidden */
nav.inline {
  display: flex;
  flex-wrap: wrap;
  opacity: 1;
  height: 49px;
  padding: 0;
  background: #f2f2f2;
  border-top: 1px #d9d8d9 solid;
  box-shadow: none;
  position: sticky;
  top: -1px;
  z-index: 1;
  pointer-events: all;
}

/***********************************
 *** secondary nav bar for tiles ***
 ***********************************/

#nav-tiles > ul {
  max-width: 400px;
}

#nav-tiles .download {
  display: none;
}

#nav-tiles .button-list {
  min-width: 200px;
}

/*
li.back-to-dashboard {
  max-width: 150px;
}
li.back-to-dashboard span.label {
  display: inline-block;
}
li.back-to-dashboard svg, li.snooze svg {
  top: -0.2em !important;
  left: 0.6em !important;
}
*/

/***********************************
 *** secondary nav bar for tiles swiper ***
 ***********************************/
#nav-tiles-swipe > ul {
  max-width: 400px;
}

/***********************************
 *** secondary nav bar for insights ***
 ***********************************/

#nav-insights {
  height: 64px;
  display: flex;
}

#nav-insights > ul:nth-child(1) {
  display: flex;
  justify-content: flex-end;
  width: calc(50% + 50px);
}

#nav-insights > ul > li {
  display: none;
}

/***********************************
 ** secondary nav bar for lessons **
 ***********************************/
#nav-lessons .button-list {
  max-width: 300px;
}

/***********************************
 * secondary nav bar for dashboard *
 ***********************************/
#nav-viewer .button-list {
  max-width: 200px;
}

/***********************************
 *** secondary nav bar for driver **
 ***********************************/
#nav-driver .button-list {
  max-width: 200px;
}

/***********************************
 **** secondary nav bar for more ***
 ***********************************/
#nav-more .button-list {
  max-width: 300px;
}

/***********************************
 ************ responsive ***********
 ***********************************/

/* one pixel bigger than iphone SE */

/* this is the width we can start showing labels */
@media (min-width: 600px) {
  #secondary-container {
    top: 0;
  }

  #secondary-container.show {
    top: 49px;
  }

  #nav-tiles .label {
    display: inline-block;
  }

  /* DESKTOP layout */

  /* secondary nav */
  nav.nav-secondary > ul > li > a {
    font-size: 13px;
    padding: 0 5px;
  }

  nav.nav-secondary a .label {
    display: block;
  }

  .buttons-wrapper {
    justify-content: flex-end;
  }
}

/******* Secondary Nav buttons - responsive *******/
@media (min-width: 600px) {
  #secondary-container {
    top: 49px;
  }
}

.logout-link {
  float: right;
  margin: 10px;
}
