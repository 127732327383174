.accordion-wrapper {
  border-bottom: 1px solid #0021474d;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  gap: 24px;
  overflow: hidden;
  padding: 21px 8px;
  transition: height 0.6s ease;
}

.with-label {
  height: 104px;
}

.expanded > .accordion-title > svg {
  transform: rotate(180deg);
}

.accordion-title {
  align-items: center;
  color: var(--neutrals-black);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.accordion-title > span {
  flex: 1;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  width: 100%;
}

.selected-data {
  background: var(--primary-light);
  border-radius: 22px;
  color: var(--primary-labels);
  display: block;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  line-height: 24px;
  margin-bottom: 12px;
  margin-top: 8px;
  padding: 4px 8px;
  width: fit-content;
}

.pill-wrapper {
  display: flex;
  gap: 8px;
}
