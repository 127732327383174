.category-modal-overlay {
  background-color: #323b4b33;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 50px;
  width: 100%;
  z-index: 10000;
}

.category-modal {
  align-items: center;
  background-color: var(--neutrals-200);
  border-radius: 12px;
  border: 1px solid var(--neutrals-300);
  box-shadow: 0px 1px 2px 0px #00000012;
  display: flex;
  justify-content: center;
  left: 50%;
  padding: 16px;
  position: absolute;
  top: 30%;
  transform: translate(-50%, -50%);
  max-width: 475px;
  width: calc(100% - 30px);
}

.distribution-modal {
  top: 50%;
  max-height: 636px;
  overflow: hidden;
}

.category-content {
  align-items: center;
  background-color: var(--neutrals-white);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  justify-content: flex-start;
  padding: 12px 8px 28px 8px;
  position: relative;
  width: 100%;
}

.distribution-modal > .category-content > ul {
  max-height: 518px;
  overflow-y: scroll;
  width: 100%;
  padding: 0 12px;
  margin: 0;
}

.distribution-modal > .category-content > ul > li {
  justify-content: flex-start;
}

.close-icon {
  cursor: pointer;
  max-width: 18px;
  position: absolute;
  right: -6px;
  top: -8px;
}

.distribution-modal > .category-content > .close-icon {
  top: -15px;
}

.modal-header {
  border-bottom: 1.5px solid #22377b;
  margin-left: 12px;
  position: relative;
  max-width: 403px;
  width: 100%;
}

.modal-header > img {
  left: -12px;
  max-width: 30px;
  position: absolute;
}

.modal-header > h2 {
  color: #22377b;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  margin: 5px 0 6px 0;
  padding-left: 24px;
  text-transform: uppercase;
}

.creation-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: scroll;
  padding: 10px 2px 0 20px;
  width: 100%;
}

.creation-form > button,
.preview-button-wrapper > button {
  align-self: center;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 700;
  height: 36px;
  line-height: 20px;
  width: fit-content;
  padding: 8px 16px;
  min-width: 122px;
  min-height: 36px;
}

.creation-form > button > span {
  margin: 0;
}
.creation-form > button:disabled,
.preview-button-wrapper > button:disabled {
  background: #f4f4f5;
  color: var(--neutrals-black);
  opacity: 0.38;
}

.pickers-wrapper {
  display: flex;
  margin: 24px 0;
  width: 100%;
}

.picker {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.picker > label {
  color: #43454c;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.loader-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0;
  width: 100%;
}

.progressLoader {
  background: #d4d5d9;
  display: inline-block;
  height: 4px;
  margin-top: 8px;
  overflow: hidden;
  position: relative;
  width: 65%;
}
.progressLoader::after {
  animation: animFw 10s linear infinite;
  background: var(--primary-default);
  box-sizing: border-box;
  content: '';
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.progressLoader-label {
  color: var(--neutrals-400);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.creation-form > .distribution-list-btn {
  min-width: 213px;
}

.content-description {
  color: var(--neutrals-black);
  font-size: 11px;
  font-style: italic;
  margin-top: 0;
}

@keyframes animFw {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.preview {
  display: flex;
  gap: 8px;
  margin-bottom: 18px;
}

.preview-thumbnail {
  height: 126px;
  position: relative;
  width: 45%;
}

.preview-thumbnail > a {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.preview-description-wrapper {
  align-items: flex-start;
  color: var(--neutrals-black);
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  gap: 8px;
  height: 126px;
  justify-content: flex-start;
  letter-spacing: 0.25px;
  line-height: 20px;
  max-width: 50%;
}

.preview-title {
  font-size: 18px;
  letter-spacing: normal;
  line-height: 22px;
  max-height: 22px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 223px;
}

.preview-duration {
  align-items: center;
  background: var(--primary-light);
  border-radius: 4px;
  color: var(--primary-medium);
  display: flex;
  gap: 4px;
  padding: 4px;
}

.preview-description {
  color: var(--neutrals-400);
  margin: 0;
  max-height: 60px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 223px;
}

.external-video-preview {
  height: 100%;
  max-height: 126px;
  object-fit: cover;
  width: 100%;
}

.cancel-button {
  background: none;
  border: 1px solid var(--primary-default);
  color: var(--primary-default);
}
.cancel-button:hover {
  background: var(--primary-light);
  color: var(--primary-default);
}

.preview-button-wrapper {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.preview-error {
  align-self: center;
  background: var(--feedback-warning-100);
  color: var(--feedback-warning-900);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding: 16px;
  text-align: center;
  width: 90%;
}

.styles-modal {
  height: 100%;
  top: 52%;
  width: 100%;
}

.styles-modal > .category-content > .modal-header {
  width: calc(100% - 16px);
  max-width: 932px;
}

.styles-modal > .category-content > .creation-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.styles-modal > .category-content > .creation-form > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.styles-modal > .category-content > .creation-form > div:nth-child(1) {
  max-width: 427px;
}

.styles-modal > .category-content {
  max-height: 600px;
  padding-bottom: 2px;
}

.style-creation-label,
.delete-warning {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--neutrals-black);
}

.delete-warning {
  font-weight: 400;
  letter-spacing: 0;
  max-width: 80%;
  text-align: center;
}

.delete-button > .loader {
  margin: 0;
}

.delete-error-message {
  background: var(--error-100);
  padding: 8px 16px;
  border-radius: 4px;
}

@media (min-width: 600px) {
  .styles-modal > .category-content > .creation-form {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
  .styles-modal {
    height: auto;
    max-width: 980px;
    top: 47%;
    width: 100%;
  }
}
