/****************** generalized dashboard overview ****************/

.ai-dashboard {
  width: 100%;
  list-style: none;
  margin: 0 auto;
  /* padding: 1em 10px; */
  padding: 0;
  overflow: hidden;
  /* so h2 can hit the edges */
  position: relative;
  transform: translate3d(0, 0, 0);

  /* do not allow drag-select text */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */

  /* font-family: 'Roboto Condensed', Arial, sans-serif; */
  font-family: Arial, sans-serif;
  font-weight: 300;
}

.ai-dashboard > li {
  float: left;
  margin: 10px 0;
  padding: 0 10px;
  height: 180px;
  min-width: 300px;
  width: 100%;
  /* mobile first */
}

.ai-dashboard > li > article {
  position: relative;
  width: 100%;
  height: 180px;
  max-height: 180px;
  border-radius: 12px;
  display: block;
  cursor: pointer;
  padding: 15px;
  margin: 0;
  margin-top: 10px;
  background: white;
  color: #2d498d;
  border: 0.75px #dadada solid;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  /* font-size: 1.2em; */
  /* font: Arial, sans-serif 14.4px; */
  font:
    normal 12px/1.4 Arial,
    sans-serif;
  text-align: left;
}
.ai-dashboard > li > article .description {
  position: relative;
  margin-top: 10px;
  color: #2d498d;
  font-size: 1.2em;
}

/* white gradient over the objective when it's not a shared target */
.ai-dashboard > li > article:not(.shared):after {
  content: '';
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 90px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  border-bottom: 20px white solid;
}

.selected-article {
  border: 1px solid #6b6b6b;
}

.ai-dashboard > li > article:hover {
  border-color: #adadad;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
}

.ai-dashboard li.break {
  height: auto;
  float: none;
  display: block;
  max-width: none;
  clear: both;
  overflow: visible;
  /* so h2 can hit the edges */
  padding: 0;
}

.ai-dashboard li.break > h2 {
  font-size: 14px;
  color: #003470;
  margin-left: -10px;
  margin-right: -10px;
  border-bottom: 1px solid #003470;
  text-align: left;
  padding: 0;
  position: relative;
  cursor: pointer;
}

.ai-dashboard.lesson-toggle {
  padding-bottom: 0;
}

.ai-dashboard > li.break:not(:first-child) h2 {
  padding-top: 25px;
}

.ai-dashboard > .break:first-child {
  padding-top: 0;
}

.ai-dashboard .break {
  padding-top: 0.5em;
}

.ai-dashboard .break label {
  background: #003470;
  padding: 5px 10px;
  border-radius: 4px 4px 0 0;
  color: #ffffff;
  margin-left: 3.25em;
  margin-right: 2em;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  width: fit-content;
}

.ai-dashboard .break .caret-wrapper {
  flex: 0 0 1.5em;
  margin-left: 10px;
  background: white;
}

.ai-dashboard .break .caret {
  color: #003470;
}

.ai-dashboard .break .description {
  color: #0a0d28;
  padding: 0 10px;
  font-weight: normal;
  text-transform: none;
  font-family: Arial, Helvetica, sans-serif;
}

.ai-dashboard .break:hover label {
  background: #cfe5ff;
  color: #00639d;
}
.ai-dashboard .break:hover img {
  filter: invert(23%) sepia(97%) saturate(1511%) hue-rotate(180deg) brightness(95%) contrast(101%);
}

.ai-dashboard .break:hover .caret {
  color: #3d96dd;
}

.ai-dashboard nav.inline {
  margin-left: -10px;
  margin-right: -10px;
  border-top: 1px #d9d8d9 solid;
  border-bottom: 1px #d9d8d9 solid;
}

.ai-dashboard nav.inline > ul {
  margin-top: 0 !important;
}

.ai-dashboard article h1,
.medias article h1 {
  position: relative;
  color: #003470;
  text-align: left;
  font:
    200 2em/45px 'Roboto Condensed',
    Arial,
    sans-serif;
  font-weight: 300;
  margin: -15px -15px 0px -15px;
  padding: 0 15px;
  border-bottom: 3px #003470 solid;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
}

.ai-dashboard article h1 svg {
  fill: #003470;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.ai-dashboard.driver-tasks li article h1 {
  text-align: left !important;
  color: white !important;
  height: fit-content;
  line-height: normal;
}

.ai-dashboard li article > svg {
  width: 1.5em;
  height: 1.4em;
}

.ai-dashboard article time {
  z-index: 1;
}

.ai-dashboard article .tags,
.medias article .tags {
  position: absolute;
  left: 15px;
  bottom: 17px;
}

.tile .tags {
  max-width: 60%;
  text-align: left;
}

.tile .tags .tag {
  margin-top: 5px;
}

.ai-dashboard article .tags > * {
  margin-right: 5px;
  margin-left: 0;
}

.ai-dashboard article .tags svg,
.medias article .tags svg {
  fill: #003470;
}

.ai-dashboard:before {
  position: absolute;
  left: 36px;
  /* align vertically with the content inside the tiles */
  top: 13px;
  line-height: 1.4;
  text-align: center;
  color: #003470;

  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}

#people .ai-dashboard:before {
  top: 0px;
}

.no-results-found {
  border: 1px #e6e7e8 solid;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 280px;
  padding: 15px;
  border-radius: 20px;
  margin: 10px 0 30px;
  color: #777;

  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-size: 1em;
  font-weight: 300;
}

.no-results-found span {
  display: block;
  text-align: center;
}

.no-results-found svg {
  width: 60px;
  height: 60px;
  margin-right: 0;
  fill: #777;
}

.no-results-found .tip {
  font-size: 70%;
}

.ai-dashboard article.attention,
.medias article.attention {
  animation:
    tile-bounce 0.8s both,
    tile-highlight 4s both;
}

.ai-dashboard article.shared.attention {
  animation:
    tile-bounce 0.8s both,
    tile-highlight-shared 4s both;
}

.ai-dashboard article.attention:after {
  display: none;
}

.delete-targets-now {
  background: white;
  color: #003470;
  display: inline-block;
  float: right;
  text-align: right;
  width: auto;
  margin: 10px;
  padding: 3px 10px;
}

.delete-targets-now .icon-wrapper svg {
  fill: #003470 !important;
  width: 12px;
  height: 12px;
}

.delete-targets-now .user-name {
  color: #003470;
}

.delete-targets-now::hover {
  color: #003470;
}

@keyframes tile-bounce {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

/* for all statuses except shared */
@keyframes tile-highlight {
  0%,
  100% {
    background: white;
  }

  50% {
    background: cornsilk;
  }
}

/* this animation needs a different background */
@keyframes tile-highlight-shared {
  0%,
  100% {
    background: #003470;
  }

  50% {
    background: #315da8;
  }
}

@media (min-width: 530px) {
  .no-results-found {
    font-size: 2em;
  }

  .ai-dashboard {
    padding: 0;
  }

  .ai-dashboard li.break > h2 {
    margin-left: -20px;
    margin-right: -20px;
  }

  .ai-dashboard > li:not(.break) {
    min-width: calc(100% / 3);
    max-width: 50%;
  }

  .tile-container article.tile h2 {
    font-family: 'Roboto Condensed';
    font-weight: normal;
  }

  .ai-dashboard:before {
    left: 47px;
  }
}

@media (min-width: 875px) {
  .ai-dashboard > li:not(.break) {
    min-width: 20%;
    max-width: calc(100% / 3);
  }

  .tile-container article.tile h1 {
    font-size: 1.9em;
  }
}

@media (min-width: 1220px) {
  .ai-dashboard > li:not(.break) {
    min-width: 20%;
    max-width: 25%;
  }
}

@media (min-width: 1565px) {
  .ai-dashboard > li:not(.break) {
    min-width: calc(100% / 5);
    max-width: 20%;
  }
}
