.image-container {
  align-items: center;
  background-color: var(--neutrals-200);
  display: flex;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  line-height: 24px;
  padding: 8px;
  width: 100%;
  gap: 4px;
}

.image-wrapper {
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: center;
  max-height: 48px;
  width: 100%;
}

.upload-container {
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23B4B4B4FF' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='24' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 16px;
  position: relative;
  width: 100%;
}

.upload-image {
  align-items: center;
  background-image: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

.upload-container > p,
.upload-image-container > span {
  color: #43454c;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  padding: 0;
}

.upload-image-container > span > .file-name {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-file {
  border: none;
  bottom: 0;
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.browse {
  border-radius: 4px;
  border: 1px solid var(--neutrals-300);
  box-shadow: 0px 1px 2px 0px #00000012;
  font-weight: 700;
  padding: 2px 10px 0 10px;
  margin-top: 20px;
  color: var(--neutrals-black);
}

.browse > svg {
  max-width: 25px;
  max-height: 25px;
  padding-bottom: 3px;
}

.upload-container > .bold {
  font-weight: 600;
}

.video-preview {
  border-radius: 8px;
  max-height: 128px;
  max-width: 310px;
  object-fit: cover;
}

.video-preview-wrapper {
  position: relative;
}

.video-close {
  cursor: pointer;
  position: absolute;
  right: -4px;
  top: -10px;
  z-index: 2;
}

.upload-image-container {
  align-items: center;
  background: #eef6ff80;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  max-height: 48px;
  padding: 12px 24px 12px 18px;
  width: 100%;
}

.upload-image-container > span {
  display: flex;
  gap: 8px;
}

.upload-image-container > span:nth-child(2) {
  cursor: pointer;
}
