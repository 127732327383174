.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-default);
}

input:focus + .slider {
  box-shadow: 0px 1px 2px 1px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 100px;
}

.slider.round:before {
  border-radius: 50%;
}

input:disabled + .slider {
  background-color: var(--neutrals-200);
  cursor: not-allowed;
}
