.insights-header {
  height: 36px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 94px 24px 20px 24px;
}

.insights-header > h1 {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  color: var(--neutrals-black);
}

.insights-header > button {
  align-items: center;
  background: none;
  border-radius: 4px;
  color: var(--primary-medium);
  display: flex;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 700;
  gap: 8px;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.1px;
  line-height: 20px;
  max-width: 174px;
  text-align: center;
  margin-bottom: 20px;
}

.insights-header > button:hover {
  color: var(--primary-default);
}

.no-content,
.delete-section-button {
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  color: var(--neutrals-400);
  padding-left: 10px;
}

.no-content {
  width: 100%;
  display: flex;
  align-items: baseline;
}

.no-section-content {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 25px;
  border-top: 1px solid var(--primary-default);
}

.no-section-content > p {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: var(--primary-default);
}

.isDragging {
  background: var(--primary-light);
  min-height: 350px;
}

.loader-dragging-wrapper {
  min-height: 350px;
  width: 100%;
  background: var(--primary-default);
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
}

.delete-section-button {
  background: none;
  border: none;
  text-decoration: underline;
  color: var(--primary-default);
  font-weight: 600;
  text-transform: lowercase;
  width: fit-content;
  padding: 0 0 0 6px;
}

.delete-section-button:hover {
  color: var(--primary-medium);
}
