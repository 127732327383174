.search-overlay {
  background-color: #323b4b33;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

.search-modal-wrapper {
  align-items: center;
  background-color: var(--neutrals-200);
  border-radius: 12px;
  border: 1px solid var(--neutrals-300);
  box-shadow: 0px 1px 2px 0px #00000012;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  left: 50%;
  padding: 16px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 799px;
  width: calc(100% - 30px);
}

.search-modal-header {
  border-bottom: 1.5px solid #22377b;
  margin-left: 12px;
  position: relative;
  max-width: 720px;
  width: 100%;
}

.search-modal-header > img {
  left: -12px;
  max-width: 30px;
  position: absolute;
}

.search-modal-header > h2 {
  color: #22377b;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  margin: 5px 0 6px 0;
  padding-left: 24px;
  text-transform: uppercase;
}

.search-modal-input {
  background: url('../../../assets/img/small-search.svg') no-repeat left #fff;
  background-position: 15px;
  border-radius: 4px;
  border: 1px solid #63656e;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 5px 0 0 12px;
  max-height: 40px;
  max-width: calc(100% - 20px);
  padding: 16px;
  padding-left: 44px;
}

.search-modal-input::placeholder {
  color: var(--neutrals-400);
  font-family: 'Inter', sans-serif;
}

.tag-filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-row-gap: 10px;
  border-radius: 4px;
  width: 100%;
  max-height: 166px;
  max-width: calc(100% - 20px);
  margin: 5px 0 0 12px;
  overflow-y: scroll;
}

.tag-filter-option {
  width: 238px;
  height: 100%;
  position: relative;
  height: 34px;
  border: 1px solid #22377b;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
}

.tag-filter-option:nth-child(3n-1) {
  border: 1px solid #22377b;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.tag-filter-option:nth-child(3n),
.tag-filter-option:nth-child(3n-1):is(:nth-last-child(1)),
.tag-filter-option:nth-child(3n):is(:nth-last-child(1)) {
  border: 1px solid #22377b;
  border-radius: 0 4px 4px 0;
}

.tag-filter-option:nth-child(3n-1):is(:nth-last-child(2)) {
  border-left: 0;
}

.tag-filter-option:nth-child(3n-2):is(:nth-last-child(2)) {
  border-radius: 4px 0 0 4px;
  border-right: 0;
}

.tag-filter-option:nth-child(3n-2):is(:nth-last-child(1)),
.tag-filter-option:nth-last-child(1) {
  border-radius: 4px;
}

.tag-filter-check {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  min-height: 35px;
  background: transparent;
  cursor: pointer;
}

.tag-filter-check::before {
  width: 100%;
  height: 100%;
}

.tag-filter-check:checked {
  background-color: #22377b;
  min-height: 36px;
}

.tag-filter-option:nth-child(3n):has(.tag-filter-check:checked) {
  border-left-color: var(--neutrals-white);
}

.tag-filter-option:nth-child(1n):has(.tag-filter-check:checked) {
  border-right-color: var(--neutrals-white);
}

.tag-filter-check:checked ~ .tag-filter-name,
.tag-filter-check:checked ~ .tag-filter-quantity {
  color: var(--neutrals-white);
  font-size: 600;
}

.tag-filter-check:checked ~ .tag-filter-quantity {
  border-color: var(--neutrals-white);
}

.tag-filter-name {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #22377b;
  position: absolute;
  top: 5px;
  left: 10px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 180px;
  cursor: pointer;
}

.tag-filter-quantity {
  position: absolute;
  top: 3.5px;
  right: 5px;
  padding: 3px 4px;
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  max-width: 26px;
  max-height: 26px;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #22377b;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.show-all {
  border-radius: 8px;
  width: 95%;
  max-width: calc(100% - 20px);
  margin-left: 10px;
  height: 42px;
}

.category-content > .tag-filter-option.show-all {
  border-radius: 8px;
}

.show-all > label,
.show-all > span {
  top: 7px;
}

.show-all > .tag-filter-check:checked,
.show-all > .tag-filter-check:checked ~ .tag-filter-name {
  cursor: default;
}

.show-all-label > svg {
  width: 20px;
  height: 23px;
  fill: var(--primary-default);
  margin-top: 3px;
}

.show-all-checked > svg {
  fill: var(--neutrals-white);
}

.tag-filter-name.show-all-label {
  max-width: 200px;
}

@media (max-width: 826px) {
  .tag-filter-option {
    width: 50%;
    height: 100%;
    position: relative;
    height: 34px;
    border: 1px solid #22377b;
    border-radius: 4px 0 0 4px;
    overflow: hidden;
  }

  .show-all {
    width: 100%;
  }

  .tag-filter-option:nth-child(even) {
    border: 1px solid #22377b;
    border-radius: 0 4px 4px 0;
    border-left: none;
  }
  .tag-filter-option:nth-child(odd) {
    border: 1px solid #22377b;
    border-radius: 4px 0 0 4px;
  }
}

@media (max-width: 600px) {
  .tag-filter-option {
    width: 100%;
    height: 100%;
    position: relative;
    height: 34px;
    border: 1px solid #22377b;
    border-radius: 4px;
    overflow: hidden;
  }

  .tag-filter-option:nth-child(3n),
  .tag-filter-option:nth-child(3n-1),
  .tag-filter-option:nth-last-child(2),
  .tag-filter-option:nth-child(3n-1):is(:nth-last-child(1)),
  .tag-filter-option:nth-child(3n-1):is(:nth-last-child(2)),
  .tag-filter-option:nth-child(3n-2):is(:nth-last-child(2)),
  .tag-filter-option:nth-child(3n-2):is(:nth-last-child(1)),
  .tag-filter-option:nth-last-child(1),
  .tag-filter-option:nth-child(even),
  .tag-filter-option:nth-child(odd) {
    border: 1px solid #22377b;
    border-radius: 4px;
  }
}
