/**** Boomers / Lessons - overall page settings ****/

section#trainer {
  background: #eaeaea;
}

section#trainer header.intro {
  border-radius: 0;
  -webkit-user-select: none;
}

/********************** lesson dashboard overrides ********************/

.lesson-container article.lesson time {
  position: absolute;
  color: #003470;
  right: 15px;
  bottom: 15px;
}

.lesson-container article.lesson .description {
  position: relative;
  margin-top: 10px;
  color: #003470;
  font-size: 1.2em;
}

article.lesson h1,
.medias.lesson h1 {
  border-color: #ffc20e;
  color: #003470;
  position: relative;
  padding-right: 50px;
  border-bottom: 0;
  --percent-watched: 0;
  overflow: visible;
}

article.lesson h1:before {
  content: '';
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background: lightgray;
  /*z-index: 1;*/
}

article.lesson h1.incomplete:after {
  content: '';
  position: absolute;
  left: 0;
  width: var(--percent-watched);
  bottom: 0;
  height: 4px;
  background: #003470;
  z-index: 0;
}
article.lesson h1.completed:after {
  content: '';
  position: absolute;
  left: 0;
  width: var(--percent-watched);
  bottom: 0;
  height: 4px;
  background: #003470;
  z-index: 0;
}

article.lesson h1.edit-action:hover::before {
  background: #003470;
}

/* this is the actual title text */
/* 
.lesson-container.ai-dashboard article h1 {
  padding-right: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.lesson-container h1 p {
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

/* the play dot at the right of the progress bar, but only on the tiles dashboard */
#trainer-lessons article.lesson h1 p:after {
  content: '';
  background: #003470;
  position: absolute;
  left: var(--percent-watched);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  bottom: -3px;
  z-index: 10;
}

.lesson-container article.resource h1 {
  padding-right: 50px;
}

article.lesson h1 .indicator {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #1ec780;
  color: #fff;
  /* color: #ffc20e; */
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

article.lesson.watched h1 {
  border-bottom-color: #bbbbbb;
}

article.lesson.bookmark .indicator {
  background: #315da8;
}

article.lesson.bookmark h1 {
  color: #315da8;
}

article.lesson.bookmark h1:after {
  background: #003470;
}

article.lesson.bookmark h1 p:after {
  background: #315da8;
}

#trainer-lessons article.lesson.bookmark h1 p:after {
  background-color: #315da8;
}

article.lesson .indicator svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  fill: white !important;
  width: 50%;
  height: 50%;
}

.swiper-slide article.lesson .indicator {
  top: calc(50% - 5px);
  transform: none;
}

/********************** worksheet data entry ********************/

table.worksheet {
  border-collapse: collapse;
  width: 100%;
}

table.worksheet col {
  min-width: 150px;
}

table.worksheet col.name {
  width: 20%;
  border-right: 0.75px lightgray solid;
  min-width: 100px;
}

table.worksheet th,
table.worksheet td {
  padding: 5px;
  text-align: left;
}

table.worksheet tbody th {
  min-width: 100px;
}

table.worksheet td {
  min-width: 120px;
}

table.worksheet tbody > tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.02);
}

table.worksheet thead th {
  vertical-align: bottom;
  border-bottom: 0.75px black solid;
}

table.worksheet tbody th,
table.worksheet tbody td {
  font-weight: normal;
  vertical-align: middle;
  position: relative;
  text-align: left;
}

table.worksheet textarea {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0.5px #003470 solid;
  min-height: 3em;
  padding: 5px;
  border-radius: 0;
}

table.worksheet textarea:focus {
  border-color: #315da8;
  background: white;
}

/*** worksheet auto save ***/

.worksheet-form .data-status {
  position: sticky;
  top: 0;
}

.worksheet-form > footer {
  position: static;
  background: transparent;
  margin-bottom: 0;
}

.worksheet-form > footer p {
  margin: 0;
}

.data-status {
  position: absolute;
  z-index: 100;
}

.data-status > * {
  display: none;
  color: gray;
  font-family: Arial, sans-serif;
  font-size: 12px;
  background: #efefef;
  border-radius: 4px;
  padding: 5px 10px;
  height: 30px;
  line-height: 1.5;
}

.data-status b {
  display: block;
  position: absolute;
  left: 10px;
  top: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  border-radius: 50%;
}

.worksheet-form.loaded .data-status > .loaded,
.worksheet-form.saving .data-status > .saving,
.worksheet-form.need-to-save .data-status > .need-to-save,
.worksheet-form.save-succeeded .data-status > .save-succeeded,
.worksheet-form.error-saving .data-status > .error-saving {
  display: inline-block;
}

.data-status > .error {
  color: red;
}

@keyframes data-throb {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.data-status .throb b {
  -webkit-animation: data-throb 1s linear infinite;
  -moz-animation: data-throb 1s linear infinite;
  -ms-animation: data-throb 1s linear infinite;
  -o-animation: data-throb 1s linear infinite;
  animation: data-throb 1s linear infinite;
}

/****************** Boomers / Lessons - Swiper ****************/

section#trainer .swiper-container {
  /*top: 49px;*/
  height: calc(100vh - 49px - 49px);
}

section#trainer .swiper-container .swiper-wrapper {
  top: 0;
  height: calc(100vh - 49px - 49px);
}

.swiper-container.lesson {
  background: #ffc20e;
}

/******* Boomers / Lessons - Cards ********/

article.card.lesson h1 {
  border-bottom: 0;
}

article.card.lesson > div {
  display: flex;
  flex-flow: column nowrap;
}

/*
  article.card.lesson > div > h1 {
    flex: 0 0 50px;
    width: 100%;
  }
  */
article.card.lesson > div > .block.video {
  flex: 0 0 auto;
  height: auto;
  width: 100%;
  padding: 0;
  max-height: 355px;
  /* necessary for old 4:3 aspect ratio videos */
  overflow: hidden;
  /* necessary for old 4:3 aspect ratio videos */
}

article.card.lesson > div > main {
  flex: 1 1 auto;
  /*height: auto;*/
  width: 100%;
}

article.card.lesson main > .block.context {
  height: 65px;
}

article.card.lesson main > .context-container {
  height: calc(100% - 65px);
}

article.card.lesson main > .context-container .active {
  position: absolute;
  left: 20px;
  right: 20px;
}

article.card.lesson > div > footer {
  position: relative;
  min-height: 25px;
}

article.card.lesson > div > footer:before {
  display: none;
}

div.block.video {
  padding: 0;
}

div.vid {
  width: 100%;
  height: 0;
  background: lightgray;
  padding-bottom: 56.25%;
}

div.vid p {
  margin: 0;
  padding: 2em 0;
  text-align: center;
}

article.card.lesson main {
  height: calc(100% - 300px);
}

article.card.lesson .description {
  font-size: 1.2em;
  color: #383838;
  padding: 0.25em 0;
  white-space: pre-wrap;
  text-align: center;
  height: 54px;
  overflow-y: auto;
  overflow-x: hidden;
}

article.card.lesson .intro {
  margin: 1em 0;
  padding: 0.5em 0;
  border-radius: 8px;
  font-size: 1.1em;
}

article.card.lesson .close-dialog svg {
  fill: white;
}

.downloads {
  list-style: none;
  margin: 1em 0;
  padding: 0;
  border-bottom: 0.75px lightgray solid;
}

.downloads > li {
  margin: 0;
  padding: 1em 0;
  display: flex;
  border-top: 0.75px lightgray solid;
}

.downloads span.button,
.downloads a.button {
  display: block;
  min-width: 100px;
  background: #003470;
  color: white;
  text-transform: uppercase;
  padding: 15px 10px;
  border-radius: 4px;
  font-size: 14px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: normal;
  text-decoration: none;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
}

.downloads span.button svg,
.downloads a.button svg {
  fill: white;
}

.downloads span.button:hover,
.downloads a.button:hover {
  background: #cfe5ff;
  color: #00639d;
}

.downloads div.info {
  padding-left: 20px;
}

.downloads .title {
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: bold;
}

.downloads div p {
  margin: 0;
}

.trainer-worksheets > .spacer {
  padding: 30px;
}

#lesson-list > li {
  max-width: 100%;
  height: auto;
}

#lesson-list > li > article > div.sub {
  height: calc(48px - 15px);
  position: relative;
}

#lesson-list > li > article > .sub.tags {
  display: inline-flex;
  text-align: center;
  justify-content: flex-start;
  align-items: baseline;
  align-content: stretch;
}

/******* horizontal tiles ********/

article.tile.horz {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  border-radius: 6px;
  color: #202124;
  border: 0.75px #dadada solid;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

article.tile.horz > header {
  flex: 0 0 50px;
}

article.tile.horz > .content {
  flex: 1 0 50px;
  overflow: hidden;
}

article.tile.horz > footer {
  flex: 0 0 50px;
}

article.tile.horz header {
  background: #003470;
  color: white;
  position: relative;
}

article.tile.horz header svg {
  width: 30px;
  height: 30px;
  fill: white;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

article.tile.horz .inner {
  display: none;
}

/* *********** ai-dashboard css ************** */

/* .ai-dashboard.horz article {
  width: auto;
  height: auto;
  max-height: none;
  cursor: pointer;
} */
.ai-dashboard.horz article > h1 {
  /* width: calc(100% + 15px + 15px); /* to take care of article padding */
}

.ai-dashboard.horz article > .sub {
  height: calc(48px - 15px);
  /* the height of the h1 minus the bottom passing of the article */
  position: relative;
}

.ai-dashboard.horz article > .sub .tags {
  position: static;
  margin-top: 12px;
  text-align: left;
}

#lesson-list.ai-dashboard.horz article > .sub .tags {
  display: inline-flex;
}

.ai-dashboard.horz article > .sub .caret {
  position: absolute;
  right: 12px;
  top: calc(50% + 7.5px);
  opacity: 0.5;
  transform: scale(1.5) translateY(-50%);
  transition: 0.3s transform cubic-bezier(0, 0.75, 0, 1);
}

.ai-dashboard.horz article > main {
  /* height: 0; */
  /* opacity: 0; */
  overflow: hidden;
  /* pointer-events: none; */
  transition: 0.3s height cubic-bezier(0, 0.75, 0, 1);
}

#lesson-list.ai-dashboard.horz article > main {
  height: 0;
}

#lesson-list.ai-dashboard.horz article.expanded > main {
  height: auto;
}

.ai-dashboard.horz article.expanded {
  height: auto;
  max-height: fit-content;
}

.ai-dashboard.horz article.expanded .caret {
  transform: scale(1.5) translateY(-50%) rotate(180deg);
}

.ai-dashboard.horz article.expanded > main {
  height: auto;
  opacity: 1;
}

.ai-dashboard > li > article:not(.shared):after {
  content: none;
}

#lesson-list.ai-dashboard > li > article {
  height: auto;
}

.user-content {
  justify-content: center;
  align-items: center;
}

.medias {
  width: 100%;
  list-style: none;
  margin: 0 auto;
  padding: 1em 10px;
  overflow-y: auto;
  /* so h2 can hit the edges */
  position: relative;
  transform: translate3d(0, 0, 0);

  /* do not allow drag-select text */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */

  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-weight: 300;
}

.medias > li {
  float: left;
  margin: 10px 0;
  margin-bottom: 25px;
  padding: 0 1rem;
  min-width: 300px;
  width: 100%;
  /* mobile first */
}

.medias > li > article {
  position: relative;
  width: 100%;
  max-width: 320px;
  min-width: 300px;
  height: 310px;
  max-height: 420px;
  border-radius: 12px;
  display: block;
  padding: 15px;
  margin: auto;
  background: white;
  color: #202124;
  border: 0.75px #dadada solid;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  font:
    normal 12px/1.4 Arial,
    sans-serif;
  text-align: left;
}

.medias > li > article .video-title {
  display: flex;
  justify-content: space-between;
}

.medias > li > article .video-title .edit-action:hover {
  cursor: pointer;
  color: #fff;
}

.medias > li > article h1.edit-action:hover {
  background-color: #003470;
  color: #fff;
}

span.edit-options {
  font-weight: 900;
  font-size: larger;
  cursor: pointer;
}

.medias > li > article svg.icon {
  fill: #003470;
}

.medias > li > article h1 {
  padding-right: 15px;
}

.lesson-container .medias article.lesson .description {
  min-height: 70px;
  max-height: 70px;
  text-overflow: ellipsis;
  padding-bottom: 10px;
}

.lesson-container .medias article.lesson .show-more.description {
  max-height: 120px;
}

.lesson-container h1 span .title-text {
  padding-right: 1.5rem;
}

/* *********** target firm-content css ************** */
.firm-content {
  min-height: 350px;
  max-height: calc(100% - env(safe-area-inset-bottom));
  background: #fff;
  border: 1px solid #999999;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  font-family: roboto condensed;
  border-radius: 8px;
}

.dialog > .box {
  max-width: 800px;
}

.firm-content .uploader-form textarea,
input {
  width: 100%;
  height: 100%;
  border: 0.5px #003470 solid;
  min-height: 3em;
  padding: 0.5rem;
  border-radius: 0;
}
.decorated.revenue-container {
  display: flex;
  align-items: flex-end;
}
.revenue-container input {
  border-radius: 4px;
}

.revenue-container > div {
  width: 50%;
}
.firm-content .uploader p {
  /* position: absolute; */
  height: 20px;
  right: 280px;

  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.firm-content .label {
  display: flex;
  justify-content: space-between;
  color: #003470;
  padding: 0.5rem 0;
  font-weight: 600;
}

.firm-content .description {
  margin-bottom: 1rem;
}

.firm-content p.file-type {
  color: #00000094;
  margin: 0.5rem 0;
}

.firm-content .lesson-header {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.6rem;
  color: #003470;
  text-align: left;
}

.firm-content label.characters {
  font-weight: 400;
  color: #00000094;
}

.firm-content section.spacer {
  margin-bottom: 2rem;
}

.firm-content .wistia-upload-btn {
  display: flex;
  column-gap: 1rem;
}

.firm-content .wistia-upload-btn .file-name {
  align-items: center;
  color: black;
  display: flex;
}

.firm-content textarea::placeholder,
input::placeholder {
  font-family: roboto condensed;
}

.firm-content textarea,
input {
  font-family: Arial, Helvetica, sans-serif;
}

.firm-content .cancel {
  display: flex;
  justify-content: center;
  color: #000;
  padding: 0.5rem 0;
  font-weight: 600;
}

.firm-content .wistia-uploader {
  min-height: 350px;
  /* height: calc(453px - 1.5rem); */
}

.firm-content .uploader-form {
  min-height: 380px;
}

.firm-content .uploader-form hr.hr-title {
  height: 4px;
  background: lightgray;
  margin: 1rem -1.5rem;
}

.upload {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
}

button.uploader-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  gap: 8px;

  width: 171px;
  height: 44px;

  background: #3d96dd;
  border-radius: 5px;
  justify-content: center;
  width: 180px;
  font-size: 1rem;
  font-weight: 400;
}

button.uploader-btn .disabled {
  cursor: not-allowed;
  background: #bbbbbb;
}

button.uploader-btn:hover {
  background: #cfe5ff;
  color: #00639d;
}

.lesson-container .medias article.lesson .edit-menu {
  position: absolute;
  background-color: #fff;
  min-width: 120px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 1;
}

.lesson-container .medias article.lesson .edit-menu a {
  color: #003470;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  font-size: 0.85rem;
}

.lesson-container .medias article.lesson .edit-menu a:hover {
  background-color: #003470;
  color: #fff;
}

/****** responsive ******/

@media (min-width: 481px) {
  article.card.lesson main {
    height: calc(100% - 430px);
  }
}

@media (min-width: 600px) {
  article.card.lesson > div {
    overflow: auto;
  }
}

@media (min-width: 875px) {
  .medias > li:not(.break) {
    min-width: 20%;
    max-width: calc(100% / 2);
  }

  .tile-container article.tile h1 {
    font-size: 1.9em;
  }
}

@media (min-width: 1220px) {
  .medias > li:not(.break) {
    min-width: 20%;
    max-width: 30%;
  }
}

@media (min-width: 1565px) {
  .medias > li:not(.break) {
    min-width: calc(100% / 5);
    max-width: 20%;
  }
}
