.expanded-insight-wrapper {
  align-items: flex-start;
  background-color: #f3f3f3;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 50px 0;
  width: 100%;
}

.content-insight-wrapper {
  padding: 32px 40px;
}

.pdf-insight-wrapper,
.pdf-insight-wrapper-header {
  padding: 0 40px;
}

.pdf-insight-wrapper-header {
  padding-top: 32px;
}

.expanded-content-wrapper {
  background-color: var(--neutrals-white);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 25px;
  max-width: 849px;
  min-height: 600px;
  overflow-y: scroll;
  position: relative;
  width: 100%;
}

.expanded-loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 600px;
  width: 100%;
  background: #d4d5d9;
  background: linear-gradient(110deg, #2223261a 8%, #f5f5f5 18%, #2223261a 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}
.expanded-loader-wrapper > .loader,
.expanded-loader-wrapper {
  margin: 0;
}

.expanded-content-wrapper > div > video {
  max-height: 400px;
  min-height: 300px;
  object-fit: cover;
}

.expanded-content-wrapper > div > header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.expanded-content-wrapper > div > header > h1,
.expanded-content-wrapper > div > header > h2 {
  font-family: 'Inter', sans-serif;
  color: #2d3748;
  font-weight: 700;
  font-size: 45px;
  line-height: 54.46px;
  margin: 12px 0;
}

.expanded-content-wrapper > div > header > h2 {
  font-size: 18px;
  line-height: 21.78px;
}

.expanded-content-wrapper > div > p {
  font-family: 'Inter', sans-serif;
  color: #718096;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
}

.pdf-wrapper {
  padding: 40px;
  background-color: var(--neutrals-200);
  margin-top: 40px;
}

.pdf-wrapper > div > p {
  color: var(--neutrals-black);
  padding: 0;
  font-size: 10px;
}

canvas.react-pdf__Page__canvas {
  margin-bottom: 50px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@media (max-width: 539px) {
  .expanded-insight-wrapper {
    padding-top: 0;
    position: absolute;
  }

  .expanded-content-wrapper {
    margin-top: 0;
  }

  .pdf-wrapper {
    padding: 20px;
    background-color: var(--neutrals-200);
    margin-top: 20px;
  }
  .pdf-wrapper > div > p {
    padding: 0 20px;
  }
}
