.default-input {
  border: 1px solid #9ea0a9;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  line-height: 24px;
  max-height: 56px;
  padding: 16px;
  width: 100%;
  position: relative;
}

.url-input {
  padding-left: 48px;
}

.default-input::placeholder {
  color: var(--neutrals-400);
  font-family: 'Inter', sans-serif;
}

.field {
  position: relative;
  color: var(--neutrals-400);
}

.field > label {
  color: var(--neutrals-400);
  font-size: 16px;
  font-weight: 400;
  left: 16px;
  line-height: 24px;
  position: absolute;
  top: 14px;
  transition: top 0.3s ease;
}

.field:focus-within > label {
  background-color: var(--neutrals-white);
  color: var(--primary-default);
  font-size: 12px;
  line-height: 16px;
  padding: 0 4px;
  position: absolute;
  top: -8px;
}

.field > .label-error,
.error-message {
  color: var(--error-default);
}

.error-message {
  font-size: 12px;
  font-weight: 400;
  padding-left: 12px;
}

.input-error {
  border: 1px solid var(--error-default);
}

.url-icon {
  position: absolute;
  top: 13px;
  left: 16px;
  z-index: 100;
}
