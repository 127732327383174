.picker-wrapper {
  background-color: #ffffff;
  border-radius: 4px;
  border: 0.8px solid #e0e0e0;
  box-shadow: 0px 0px 25px 0px #0000001f;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 188px;
  order: -1;
  position: relative;
  width: 100%;
}

.rgb-switchers > .react-colorful > .react-colorful__saturation,
.rgb-switchers > .react-colorful > .react-colorful__hue {
  display: none;
}

.picker-wrapper > .react-colorful {
  max-height: 174px;
  width: 100%;
}

.picker-wrapper > .react-colorful > .react-colorful__saturation {
  border-radius: 4px 4px 0 0;
}

.react-colorful__hue,
.react-colorful__alpha {
  border-radius: 500px;
  left: 8px;
  max-height: 10px;
  max-width: 165px;
  top: 8px;
  width: 100%;
}

.react-colorful__pointer {
  box-shadow: 0px 0px 5px 0px #000000b2;
  height: 18px !important;
  margin: 0 !important;
  width: 18px !important;
}

.react-colorful__alpha,
.react-colorful__alpha-pointer {
  border-radius: 500px !important;
  margin-top: 8px;
}

.color-inputs {
  display: flex;
  gap: 8px;
  margin-left: 8px;
  margin-top: 8px;
  padding-bottom: 8px;
  width: calc(100% - 16px);
}

.rgb-wrapper {
  display: flex;
  max-width: 105px;
}

.rgb-label {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 8px;
  font-weight: 500;
  text-transform: uppercase;
}

.hex-input-wrapper > input,
.rgb-input {
  border-radius: 4px;
  border: 0.8px solid #eeeeee;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-weight: 400;
  max-height: 28px;
  padding: 4px 8px;
  text-transform: uppercase;
}

.open-picker-btn {
  border-radius: 50%;
  border: 2px solid #eeeeee;
  height: 48px;
  width: 48px;
}

.btn-picker-wrapper {
  position: relative;
}

.color-picker-wrapper {
  position: absolute;
  top: 54px;
  z-index: 1000;
}
