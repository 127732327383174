.style-preview-wrapper {
  padding: 16px;
  border: 1px solid #9ea0a9;
  border-radius: 5px;
  max-height: 525px;
  height: 100%;
}

.style-preview-button:disabled {
  cursor: not-allowed;
}

.save-button-container {
  padding-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style-preview {
  height: 100%;
  border: 1px solid #696969;
  border-radius: 3px;
  background: #eff8ff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 18.25px;
  justify-content: flex-start;
  max-height: 490px;
}

.style-preview-header {
  height: 98px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 48px;
}

.style-preview-header > span {
  font-family: 'Inter', sans-serif;
  font-size: 10.65px;
  font-weight: 400;
  line-height: 12.88px;
  text-align: center;
}

.style-preview-header > img {
  max-height: 90px;
  max-width: 50%;
  object-fit: fill;
}

.style-preview-title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.style-preview-title,
.style-preview-curated,
.style-preview-description,
.content-description-wrapper > span,
.style-preview-duration > span {
  font-family: 'Inter', sans-serif;
  font-size: 10.65px;
  font-weight: 400;
  line-height: 16.73px;
  text-align: center;
}

.style-preview-title {
  font-size: 18.25px;
  font-weight: 500;
  line-height: 22.09px;
}

.style-preview-curated {
  color: #9ea0a9;
}

.style-preview-description {
  color: #000000b2;
  width: 100%;
  padding: 0 16%;
}

.style-preview-content {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: flex-start;
}

.content-image-preview {
  background: #343434;
  border-radius: 4px;
  max-width: 65px;
  min-height: 75px;
  width: 100%;
}

.content-description-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  max-height: 75px;
  align-items: flex-start;
}

.content-description-title {
  font-size: 13.69px;
  font-weight: 400;
  color: #9ea0a9;
}

.content-description-viewMore {
  font-weight: 400;
  color: var(--primary-default);
  text-decoration: underline;
}

.sharing-links > span > svg {
  max-height: 12px;
  max-width: 12px;
}

.sharing-links.sharing-links-preview {
  gap: 4px;
}

.style-preview-duration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
}

.style-preview-duration > span {
  line-height: 15.21px;
  letter-spacing: 0.2px;
  text-align: left;
  color: var(--primary-default);
}

.style-preview-button > .loader {
  margin-top: 0;
}

.style-preview-section {
  padding-bottom: 150px;
}

@media (min-width: 600px) {
  .style-preview-button,
  .style-preview-button:disabled,
  .style-preview-button:active {
    align-self: center;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 700;
    height: 36px;
    line-height: 20px;
    width: fit-content;
    padding: 8px 16px;
    min-width: 122px;
    min-height: 36px;
    margin-top: 16px;
  }

  .style-preview-section {
    padding-bottom: 0;
  }

  .sharing-links > span > svg {
    max-height: 18px;
    max-width: 18px;
  }
  .content-image-preview {
    background: #343434;
    border-radius: 4px;
    max-width: 85px;
    min-height: 75px;
    width: 100%;
  }
}
