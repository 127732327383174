.preview-overlay {
  background-color: #323b4b33;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 10000;
}

.preview-modal-wrapper {
  background-color: var(--neutrals-white);
  height: fit-content;
  left: 50%;
  max-width: 700px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.preview-modal-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: fit-content;
  justify-content: flex-start;
  max-height: 720px;
  overflow: scroll;
  width: 100%;
}

.preview-modal-content > div {
  color: black;
  width: 100%;
}

.preview-modal-header {
  align-items: center;
  display: flex;
  height: 126px;
  justify-content: space-between;
  min-height: 126px;
  padding: 17.7px 30px;
}

.preview-modal-header > img {
  height: 100%;
  max-height: 120px;
  max-width: 300px;
  object-fit: contain;
  object-position: left center;
  width: 75%;
}

.preview-modal-header > span {
  color: var(--neutrals-white);
  min-width: 90px;
}

.preview-modal-header > span,
.body-header > h2,
.body-header > h3,
.preview-modal-body > p,
.content-data > *,
.private-content-pill,
.content-duration,
.preview-modal-footer > li {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  margin: 0;
  text-align: right;
}

.preview-modal-body {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
  max-width: calc(100% - 30px);
}

.body-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.body-header > h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  text-align: center;
}

.body-header > h3,
.preview-modal-body > p {
  color: var(--neutrals-black);
  line-height: 22px;
  text-align: center;
}

.preview-modal-body > p {
  opacity: 0.7;
}

.content-item {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
}

.content-item > img {
  border-radius: 5px;
  height: 114px;
  max-width: 111px;
  object-fit: cover;
  width: 28%;
  border: 0.5px solid var(----neutrals-300);
}

.content-item > .content-data {
  align-items: flex-start;
  color: var(--neutrals-black);
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 18px;
  justify-content: space-between;
  line-height: 22px;
  width: 100%;
}

.content-data > .content-data-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content-data > .content-data-header > a > h4 {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  max-height: 44px;
  max-width: 313px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: initial;
  white-space: nowrap;
}

.content-data > p {
  font-size: 14px;
  line-height: 20px;
  max-height: 44px;
  max-width: 350px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  text-transform: initial;
  white-space: nowrap;
  color: var(--neutrals-400);
}

.content-data-header > a {
  color: var(--primary-medium);
  line-height: 22px;
  text-decoration: underline;
  cursor: pointer;
}

.sharing-links {
  align-items: center;
  display: flex;
  gap: 8px;
  height: 32px;
  padding: 4px;
  padding-right: 0;
}

.sharing-links > a {
  color: var(--primary-default);
  cursor: pointer;
  max-width: 24px;
  text-decoration: none;
}

.sharing-links > a > svg {
  max-width: 18px;
  max-height: 18px;
}

.sharing-links > .external-share-division {
  background: #a1cdff;
  height: 17px;
  width: 1px;
}

.private-content-pill {
  background: var(--primary-light);
  border-radius: 22px;
  color: var(--primary-medium);
  font-weight: 600;
  line-height: 24px;
  max-width: 122px;
  padding: 4px 8px;
  text-align: center;
}

.content-duration {
  align-items: center;
  background: var(--primary-light);
  border-radius: 5px;
  color: var(--primary-default);
  display: flex;
  gap: 4px;
  height: 28px;
  justify-content: center;
  padding: 4px;
}

.content-duration > span {
  color: var(--primary-default);
  letter-spacing: 0.25px;
}

.body-division {
  background: var(--neutrals-black);
  max-width: 580px;
  min-height: 1px;
  opacity: 0.38;
  width: 90%;
}

.preview-modal-content > .body-division {
  width: 90%;
}

.preview-modal-footer {
  align-items: center;
  color: var(--neutrals-black);
  display: flex;
  justify-content: center;
  opacity: 0.38;
  padding-bottom: 150px;
}

.preview-modal-footer > li {
  font-size: 12px;
}

.preview-modal-footer > li > a {
  left: -8px;
  position: relative;
}

.preview-modal-footer > li > a,
.preview-modal-footer > li > a:visited {
  color: var(--neutrals-black);
}

@media (min-width: 600px) {
  .preview-modal-body {
    max-width: 580px;
  }

  .preview-modal-header {
    padding: 17.7px 64px;
  }
  .sharing-links {
    gap: 6px;
  }
  .sharing-links > a > svg {
    max-width: 24px;
    max-height: 24px;
  }
  .preview-modal-content > .body-division {
    width: 100%;
  }
  .preview-modal-footer {
    padding-bottom: 24px;
  }
}
